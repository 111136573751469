<template>
  <v-dialog v-model="dialog">
    <template v-slot:activator="{ on }">
      <v-btn v-show="show" dense icon v-on="on">
        <v-icon>mdi-link-variant</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="justify-center"> Today </v-card-title>
      <v-card-text>
        <v-timeline>
          <v-timeline-item
            v-for="(link, idx) in items"
            :key="idx"
            color="red lighten-2"
          >
            <template v-slot:opposite>
              <span>{{ link.date }}</span>
            </template>
            <v-card
              class="elevation-2"
              :to="{ name: 'case', params: { id: link.id } }"
            >
              <v-card-title class="text-h5"> {{ link.grno }} </v-card-title>
              <v-card-text>
                {{ link.issue }}
              </v-card-text>
            </v-card>
          </v-timeline-item>
        </v-timeline>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    items: { type: Array },
    show: { type: Boolean },
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    printOut(item) {
      console.log(item);
    },
    cardClose() {
      this.dialog = false;
    },
  },
};
</script>
