import axios from 'axios';

export async function retrieveFile(filePath) {
  const bluehostRetrieveUrl = `${process.env.VUE_APP_API_BASE_URL}/retrieveFile.php?filePath=${filePath}`;

  try {
    const response = await axios.get(bluehostRetrieveUrl, { responseType: 'blob' });
    if (response.status === 200) {
      let filename = 'default.pdf'; // Default filename

      // Check if the content-disposition header is present
      if ('content-disposition' in response.headers) {
        const disposition = response.headers['content-disposition'];
        const match = disposition.match(/filename="(.+)"/);
        if (match && match.length > 1) {
          filename = match[1];
        }
      }

      // Create a URL for the blob object
      const fileUrl = URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
      return { fileUrl, filename };
    } else {
      throw new Error('Failed to retrieve file');
    }
  } catch (error) {
    if (error.response && error.response.status === 301) {
      console.error('File not found.');
      throw new Error('File not found.');
    } else {
      console.error('Error retrieving file from server:', error.message);
      throw new Error('An error occurred while retrieving the file.');
    }
  }
}
