<template>
  <v-dialog v-model="dialog" max-width="700">
    <!-- Activator button -->
    <template v-slot:activator="{ on }">
      <!-- <v-btn small text v-on="on">
        Report
      </v-btn> -->
      <v-list-item link v-on="on">
        <v-list-item-icon>
          <v-icon>mdi-flag-variant-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Report</v-list-item-title>
      </v-list-item>
    </template>

    <!-- Report entry form -->
    <v-form v-model="dialogValid" ref="dialog">
      <v-card>
        <v-card-title>
          <span class="headline">Report Entry</span>
        </v-card-title>
        <v-card-subtitle>
          <span class="subtitle">We're sorry something's wrong. How can we help?</span>
        </v-card-subtitle>
  
        <v-card-text>
          <v-container>
            <!-- Issue type selection -->
            <v-autocomplete
              v-model="selected"
              :items="issueTypes"
              auto-select-first
              chips
              clearable
              deletable-chips
              small-chips
              label="Report Issue"
              prepend-icon="mdi-filter-variant"
              @change="onIssueTypeChange"
              @input="onIssueTypeChange"
              @keydown.enter.stop.prevent
            >
              <template v-slot:selection="{ attrs, item, select, selected }">
                <v-chip
                  v-bind="attrs"
                  :input-value="selected"
                  close
                  @click="select"
                  @click:close="remove()"
                >
                  <span>{{ item }}</span>&nbsp;
                </v-chip>
              </template>
            </v-autocomplete>

            <v-divider></v-divider>
            
            <!-- Detailed Description Textarea -->
            <v-textarea
              v-model="description"
              label="Describe the issue in detail"
              required
            ></v-textarea>
          </v-container>
        </v-card-text>
        
        <!-- Confirmation dialog -->
        <v-dialog v-model="confirmationDialog" max-width="300">
          <v-card>
            <v-card-title>Report Submitted</v-card-title>
            <v-card-text>
              Your feedback has been submitted successfully.
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeConfirmationDialog">OK</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        
        <!-- Card actions -->
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="cardClose">Cancel</v-btn>
          <v-btn color="primary" text @click="cardSave" :disabled="!dialogValid">Submit</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { settingsCollection, db, usersCollection } from "../../firebaseDb";
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState(["currentUser", "userProfile"]),
  },

  data() {
    return {
      dialog: false,
      dialogValid: true,
      issueTypes: [], 
      description: '',
      selected: '',
      confirmationDialog: false,
      fieldRules: [(v) => !!v || "Field is required"],
      author: "",
      authorName: "",
      caseRef: "",
    };
  },
  async created() {
    try {
      const querySnapshot = await settingsCollection.doc("reportoption").get();
      if (querySnapshot.exists) {
        // Fetch issue types from Firestore and populate the issueTypes array
        this.issueTypes = querySnapshot.data().param;
        
      }
       // Set author and authorName after fetching currentUser
      this.author = this.currentUser ? this.currentUser.uid : '';
      this.authorName = this.currentUser ? this.currentUser.displayName : '';
      this.caseRef = this.$route.params.id;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },
  methods: {
    cardClose() {
      this.dialog = false;
    },
    remove() {
      this.selected = '';
    },
    clearUI() {
      this.selected = '';
      this.description = '';
      this.selectedIndex = '';
    },
    cardSave() {
      if (this.selected.length === 0 || this.description.trim() === '') {
        // console.log('Please select an issue type and provide a description.');
        return;
      }
      this.closeConfirmationDialog();
      this.confirmationDialog = true;
      this.createReport();
    },
    closeConfirmationDialog() {
      this.confirmationDialog = false;
      this.dialog = false;
    },
    onIssueTypeChange() {
      // Get the index of the selected issue type
      this.selectedIndex = this.issueTypes.indexOf(this.selected);
    },
    createReport() {
      // Add a document to the "reports" collection
      db.collection("reports").add({
        user: "users/" + this.author,
        userReport: [     
          {
            caseRef: this.caseRef,
            reportParam: this.selectedIndex,
            reportTopic: this.selected,
            reportDetails: this.description,
            reportIsActive: true,
          }
        ],
      })
      .then((docRef) => {
        //console.log("Document written with ID: ", docRef.id);

        // Get reference to the user document and update it
        const userRef = usersCollection.doc(this.author);
        const docReportRef = "reports/" + docRef.id; // Fixed typo here

        userRef.get().then((docSnapshot) => {
          if (docSnapshot.exists) {
            // Check if reportsArray field exists
            const userData = docSnapshot.data();
            const reportsArray = userData.reportsArray || [];

            // Update the user document with the new report ID
            userRef.update({
              reportsArray: [...reportsArray, docReportRef]
            })
            .then(() => {
              //console.log("Report ID added to user's reportsArray");
            })
            .catch((error) => {
              console.error("Error updating user document: ", error);
            });
          } else {
            // If the user document doesn't exist, handle the error accordingly
            console.error("User document does not exist.");
          }
        })
        .catch((error) => {
          console.error("Error fetching user document: ", error);
        });
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
      this.clearUI();
    },
  },

};
</script>
