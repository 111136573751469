<template>
  <v-container style="max-width: 1200px;" class="full-text-case">
    <div
      class="action-btns"
      flat
    >
      <v-navigation-drawer
        permanent
        expand-on-hover
        dark
        mini-variant-width = 30
        width="150"
        :class="navDesign"
      >
        <v-list
          nav
          dense
        >
          <v-list-item link @click="downloadWithVueResource" :disabled="inval">
            <v-list-item-icon>
              <v-icon>mdi-tray-arrow-down</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Full text</v-list-item-title>
          </v-list-item>

          <CaseReport />
        </v-list>
      </v-navigation-drawer>
    </div>

    <v-card>
      <v-snackbar
        :color="snackColor"
        v-model="snackbar"
        :timeout="2000"
        multi-line
        top
      >
        <v-layout align-center pr-4>
          <v-icon class="pr-3" dark large>{{ snackIcon }}</v-icon>
          <v-layout column>
            <div>
              <strong>{{ snackTitle }}</strong>
            </div>
            <div>{{ response }}</div>
          </v-layout>
        </v-layout>
      </v-snackbar>
      
      <div class="text-justify pa-3" :class="{ 'ma-10': $vuetify.breakpoint.smAndUp, 'ma-3': $vuetify.breakpoint.xsOnly }">
        <div ref="body" @copy.prevent="highlight">
          <p>{{ grno.idx }}<br />{{ caseDate }}</p>
          <template v-for="(reference, idx) in grno.references">
            <v-row :key="'A' + idx">
              <v-col cols="12" class="text-center mb-10">
                <hr class="mb-8 custom-hr" />
                <h3>{{ reference.refno }}</h3>
                <h2 class="mb-3">{{ reference.short }}</h2>
                <span style="white-space: pre-wrap">{{ reference.title }}</span>
              </v-col>
            </v-row>
          </template>
          <hr />
          <template v-for="(cat, idx) in grno.categories">
            <v-breadcrumbs :items="cat.path" :key="'B' + idx" class="d-flex justify-center">
              <template v-slot:divider>
                <v-icon>mdi-forward</v-icon>
              </template>
            </v-breadcrumbs>
          </template>
          
          <hr class="mb-8" />

          <!--    OLD SALIENT DOCTRINES     -->
          <h3 class="custom-h3 text-center" v-if="grno?.syllabus">Salient Doctrines or Principles</h3>
          <p />


          <p v-if="grno?.syllabus" v-html="grno?.syllabus" />


          <!--  THIS IS TO DISPLAY THE NEW SALIENT DOCTRINES MOVED IN ISSUES COLLECTION    -->
          <!--  TEMPORARILY COMMENTED BECAUSE THE PUBLISHED CASE'S SALIENT DOCTRINES IS NOT YET MIGRATED.   -->

          <template v-if="issues && issues.length > 0">
            <template v-for="(issue, idx) in issues">
              <v-row :key="'C' + idx">
                <v-col cols="12">
                  <h3 v-if="issue.syllabus" class="custom-h3 text-center">Salient Doctrines or Principles</h3>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <h5 v-if="issue.syllabus">({{ issue.issueId }})</h5>
                </v-col>

                <v-col cols="12" sm="12" md="12" v-if="issue.syllabus">
                  <span style="white-space: pre-wrap">{{ issue.syllabus }}</span>
                </v-col>
              </v-row>
            </template>
          </template>

          <!--            end of comment         -->

          <hr class="my-8" />
          <!-- <articleAd :userPlan="userProfile.role" class="mb-4"/> -->
          <h3 class="custom-h3 text-center" v-if="grno.brief">Case Brief</h3>
          <p />
          <p><span v-html="grno.brief"></span></p>
          <hr class="my-8" v-if="grno.brief"/>
        </div>
        <!-- <articleAd :userPlan="userProfile.role" class="mb-4"/> -->
        <div id="no-copy" @copy.prevent="noCopy" v-if="issues">
          <h3 class="custom-h3 text-center">Issues and Rulings</h3>
          <template v-for="(issue, idx) in issues">
            <v-row :key="'D' + idx">
              <v-col cols="12" sm="12" md="12">
                <h5>Issue {{ issue.issueId }}</h5>
              </v-col>
              <v-col cols="12" sm="1" md="1">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-show="calcControl(issue)" color="green" v-on="on"
                      >mdi-file-check</v-icon
                    >
                    <v-icon v-show="calcRein(issue)" color="amber" v-on="on"
                      >mdi-file-restore</v-icon
                    >
                    <v-icon v-show="calcRepeal(issue)" color="red" v-on="on"
                      >mdi-file-excel</v-icon
                    >
                  </template>
                  <span>{{ issue.status }}</span>
                </v-tooltip>
              </v-col>
              <v-col cols="12" sm="1" md="1" class="pa-2">
                <CaseViewTimeline :items="issue.links" :show="issue.show" />
              </v-col>
              <v-col cols="12" sm="8" md="8">
                <p class="blockquote-title" v-html="issue.issue" />
              </v-col>
              <!--           <v-col cols="12" sm="1" md="1">
              <v-icon>mdi-link-variant</v-icon>
              </v-col> -->
              <v-col cols="12" sm="12" md="12">
                <h5>Ruling {{ issue.issueId }}</h5>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <p style="white-space: pre-wrap">{{ issue.ruling }}</p>
              </v-col>
            </v-row>
          </template>
          <br />
          <hr class="my-8"/>
          <h3 class="custom-h3 text-center" v-if="grno.digest">Case Digest</h3>
          <p v-html="grno.digest" />
        </div>
      </div>
    </v-card>
  <!-- Floating Action Button -->
  <transition name="fade">
    <v-btn
      v-show="showScrollTopButton"
      fab
      fixed
      bottom
      right
      color="primary"
      @click="scrollToTop"
      class="scroll-to-top-btn"
    >
      <v-icon>mdi-chevron-up</v-icon>
    </v-btn>
  </transition>
</v-container>
</template>

<script>
import { db } from "../../firebaseDb";
import { mapState, mapGetters } from "vuex";
//import firebase from "firebase/app";
import moment from "moment";
import CaseReport from "./CaseReport";
import CaseViewTimeline from "./CaseViewTimeline.vue";
// import axios from "axios";
import { retrieveFile } from '../utils/retrieveFileUtils';


export default {
  computed: {
    ...mapState(["currentUser", "userProfile"]),
    ...mapGetters(['darkTheme']),
    navDesign() {
      return this.darkTheme ? 'nav-dark' : 'nav-light';
    },
  },
  components: {
    CaseReport,
    CaseViewTimeline,
  },
  data() {
    return {
      issues: [],
      snackbar: false,
      snackColor: "",
      snackIcon: "",
      snackTitle: "",
      response: "",
      grno: {},
      caseDate: "",
      url: "",
      inval: true,
      containerWidth: 90,
      showScrollTopButton: false,
    };
  },
  beforeRouteUpdate(to, from, next) {
    this.loadContent(to.params.id);
    next();
  },
  created() {
    this.loadContent(this.$route.params.id);
    this.retrieveFileWrapper(this.$route.params.id);
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    },
    handleScroll() {
      this.showScrollTopButton = window.scrollY > 100;
    },
    loadContent(uid) {
      this.grno = {};
      this.issues = [];
      let dbRef = db.collection("cases").doc(uid);
      dbRef
        .get()
        .then((doc) => {
          this.grno = doc.data();
          this.grno.uid = uid;
          let tempData = {};
          tempData.references = doc.data().references;
          if (typeof tempData.references != "undefined") {
            tempData.references.forEach((e, index) => {
              if (index != 0) {
                tempData.caseId += ";" + e.refno;
              } else {
                tempData.caseId = e.refno;
              }
            });
          }
          this.grno.ref = tempData.caseId;

          this.grno.issues.forEach((element) => {
            element.get().then((res) => {
              let issueData = res.data();
              issueData.ref = element;
              issueData.show = false;
              if (issueData.related) {
                issueData.related.get().then((a) => {
                  issueData.links = [];
                  a.data().links.forEach((x) => {
                    db.doc("cases/" + x.caseRef)
                      .get()
                      .then((c) => {
                        //console.log(c.data());
                        issueData.show = true;
                        let caseData = {};
                        caseData.references = c.data().references;
                        if (typeof caseData.references != "undefined") {
                          caseData.references.forEach((e, index) => {
                            if (index != 0) {
                              caseData.caseId += ";" + e.refno;
                            } else {
                              caseData.caseId = e.refno;
                            }
                          });
                        }
                        caseData.id = x.caseRef;
                        caseData.grno = caseData.caseId;
                        caseData.date = moment
                          .utc(new Date(x.date * 1000))
                          .local()
                          .format("LL");
                        db.doc("issues/" + x.issueRef)
                          .get()
                          .then((i) => {
                            caseData.issue =
                              i.data().issueId + ". " + i.data().issue;
                            issueData.links.push(caseData);
                          });
                      });
                  });
                  this.issues.push(issueData);
                  this.issues.sort((a, b) => a.issueId - b.issueId);
                });
              } else {
                this.issues.push(issueData);
              }
            });
          });
          //console.log(this.grno);
          // change the firestore timestamp to datetime-local
          //console.log(this.grno.date);
          //console.log(this.grno.date.seconds);
          //console.log(moment.unix(this.grno.date.seconds).format("YYYY-MM-DD"));
          this.caseDate = moment
            .utc(this.grno.date.toDate())
            .local()
            .format("LL");
          // turn back to firestore timestamp
          // console.log(this.grno.date);
          // console.log(
          //   firebase.firestore.Timestamp.fromDate(
          //     new Date(this.caseDate)
          //   ).toDate()
          // );
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // retrieveFile(uid) {
    //   // supports only 1 file per directory
    //   storage
    //     .ref("fulltext/" + uid)
    //     .listAll()
    //     .then((res) => {
    //       res.items.forEach((itemRef) => {
    //         storage
    //           .ref("fulltext/" + uid + "/" + itemRef.name)
    //           .getDownloadURL()
    //           .then((url) => {
    //             this.url = url;
    //             this.inval = false;
    //           });
    //       });
    //     });
    // },

    async retrieveFileWrapper(uid) {
      const filePath = "fulltext/" + uid;

      try {
        const { fileUrl } = await retrieveFile(filePath);
        this.url = fileUrl;
        this.inval = false;
      } catch (error) {
        console.error(error);
      }
    },

    forceFileDownload(response) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      var filename = this.grno.ref.trim();
      link.href = url;
      link.setAttribute(
        "download",
        filename.replace(/[\\\\/:*?"<>|]/g, "") + ".pdf"
      ); //or any other extension
      document.body.appendChild(link);
      link.click();
    },
    downloadWithVueResource() {
      this.$http({
        method: "get",
        url: this.url,
        responseType: "arraybuffer",
      })
        .then((response) => {
          this.forceFileDownload(response);
        })
        .catch((error) => console.log(error));
    },
    toClipboard() {
      // create a div container for our copy
      let container = document.createElement("div");

      // hide the container. required for copying to clipboard
      container.style.position = "fixed";
      container.style.pointerEvents = "none";
      container.style.opacity = 0;

      // retrieve highlighted text
      let range = window.getSelection().getRangeAt(0);

      // clone the text
      let selectionContent = range.cloneContents();

      // create the citation text
      let cText = "";
      this.grno.references.forEach((e) => {
        let cite = e.refno + ", " + this.caseDate + ". " + e.short;
        if (cText != "") {
          cText += ";" + cite;
        } else {
          cText = cite;
        }
      });
      cText += ".";

      // create the text node and append to the clipboard
      let citation = document.createTextNode(cText);
      let para = document.createElement("p");
      let footnote = document.createElement("em");
      footnote.appendChild(citation);
      para.appendChild(footnote);
      selectionContent.appendChild(document.createElement("p"));
      selectionContent.appendChild(para);

      // put selected data with citation to container
      container.appendChild(selectionContent);

      // the following are needed to put data to the clipboard
      // add the newly created HTML to the webpage
      document.body.appendChild(container);

      // reset the highlighted text to use the new HTML for copying
      window.getSelection().removeAllRanges();
      let newRange = document.createRange();
      newRange.selectNode(container);
      window.getSelection().addRange(newRange);

      // copies text to clipboard
      document.execCommand("copy");

      // remove the temporary HTML container
      document.body.removeChild(container);
      //console.log(container.innerHTML);
      this.snackbar = true;
      this.snackColor = "success";
      this.snackIcon = "check_circle";
      this.snackTitle = "Success";
      this.response = "Citation has been copied!";
    },
    highlight() {
      // create a div container for our copy
      let container = document.createElement("div");

      // hide the container. required for copying to clipboard
      container.style.position = "fixed";
      container.style.pointerEvents = "none";
      container.style.opacity = 0;

      // retrieve highlighted text
      let range = window.getSelection().getRangeAt(0);
      // clone the text
      let selectionContent = range.cloneContents();

      container.appendChild(selectionContent);

      // highlight function off for now
      // let copiedText = {
      //   caseId: this.$route.params.id,
      //   date: this.caseDate,
      //   texts: [container.innerHTML],
      // };
      // if (this.userProfile.highlights == null) {
      //   this.userProfile.highlights = [];
      // }

      // // find the grno index so we can append our text data
      // let index = this.userProfile.highlights.findIndex((highlight) => {
      //   if (highlight.caseId == this.$route.params.id) {
      //     return true;
      //   }
      // });

      // // make new highlights data or append text
      // if (index == -1) {
      //   this.userProfile.highlights.push(copiedText);
      // } else {
      //   this.userProfile.highlights[index].texts.push(container.innerHTML);
      // }

      // // auto save to profile for now
      // this.saveHighlights();

      // move back to clipboard
      //this.toClipboard();
      this.copyTextWithCitation();
    },
    saveHighlights() {
      let dbRef = db.collection("users").doc(this.currentUser.uid);
      dbRef
        .update({ ...this.userProfile })
        .then(() => {
          this.snackbar = true;
          this.snackColor = "success";
          this.snackIcon = "check_circle";
          this.snackTitle = "Success";
          this.response = "Highlight has been saved!";
        })
        .catch((error) => {
          console.log(error);
          this.snackbar = true;
          this.snackColor = "error";
          this.snackIcon = "error";
          this.snackTitle = "Error";
          this.response = error;
        });
    },
    
    //  Changes for the copy and paste Function
    async copyTextWithCitation() {
      try {
        const selection = window.getSelection();
        const range = selection.getRangeAt(0);
        const selectedText = range.toString();
        const citationText = this.generateCitationText();

        const isIOS = /iPhone|iPod|iPad/.test(navigator.userAgent) && !/Macintosh/.test(navigator.userAgent) && !window.MSStream;

        // const userAgent = navigator.userAgent;
        // console.log('User Agent:', userAgent);
        // console.log('Is iPad or iPhone:', isIOS);

        if (isIOS) {
          // Code for iPhone and iPod
          await this.copyTextToClipboardForiPhone(selectedText, citationText);
          return; // Exit the function early for iOS
        }

        if (!this.copyInProgress) {
          this.copyInProgress = true;

          const container = document.createElement('div');
          const span = this.createSpanWithCitation(range.cloneContents(), citationText);

          container.appendChild(span);
          document.body.appendChild(container);

          // Create a hidden contentEditable div
          const hiddenDiv = document.createElement('div');
          hiddenDiv.classList.add('hidden-div');
          hiddenDiv.contentEditable = true;
          hiddenDiv.innerHTML = container.innerHTML;

          document.body.appendChild(hiddenDiv);

          // Select all content in the hidden div
          const rangeForCopy = document.createRange();
          rangeForCopy.selectNodeContents(hiddenDiv);
          selection.removeAllRanges();
          selection.addRange(rangeForCopy);

          // Execute copy command
          document.execCommand('copy');

          // Remove the temporary elements with a delay
          setTimeout(() => {
            document.body.removeChild(container);
            document.body.removeChild(hiddenDiv);
            this.cleanupAfterCopy(container);

            // Reset the copy in progress flag
            this.copyInProgress = false;
          }, 100);
        }
      } catch (error) {
        console.error('Unable to copy text:', error);
        // Handle error or provide a fallback for unsupported browsers
      }
    },

    copyTextToClipboardForiPhone(selectedText, citationText) {
      try {
        const textToCopy = `${selectedText}\n(${citationText})`;

        if (navigator.clipboard) {
          // For modern browsers supporting the Clipboard API
          navigator.clipboard.writeText(textToCopy)
            .then(() => {
              this.showCopySuccessSnackbar();
              const selection = window.getSelection();
              selection.removeAllRanges(); // Clear the selection
            })
            .catch((error) => {
              console.error('Unable to copy text to clipboard:', error);
              // Handle error or provide a fallback for unsupported browsers
            });
        } else {
          // For browsers without Clipboard API support (e.g., iOS Safari)
          const textarea = document.createElement('textarea');
          textarea.value = textToCopy;
          textarea.style.position = 'fixed';
          textarea.style.opacity = 0;
          document.body.appendChild(textarea);
          textarea.select();

          try {
            document.execCommand('copy');
            this.showCopySuccessSnackbar();
          } catch (error) {
            console.error('Unable to copy text to clipboard:', error);
            // Handle error or provide a fallback for unsupported browsers
          } finally {
            document.body.removeChild(textarea);
            const selection = window.getSelection();
            selection.removeAllRanges(); // Clear the selection
          }
        }
      } catch (error) {
        console.error('Error copying text to clipboard:', error);
        // Handle any errors that occur during the copying process
      }
    },

    createCopyContainer() {
      let container = document.createElement("div");
      container.style.position = "fixed";
      container.style.pointerEvents = "none";
      container.style.opacity = 0;
      return container;
    },

    generateCitationText() {
      let cText = "";
      this.grno.references.forEach((e) => {
        let cite = e.refno + ", " + this.caseDate + ". " + e.short;
        cText += (cText !== "" ? ";" : "") + cite;
      });
      return cText + ".";
    },

    createSpanWithCitation(selectionContent, cText) {
      let span = document.createElement('span');
      span.appendChild(selectionContent);
      span.innerHTML += `<br><p style="font-style: italic; color: #888;">${cText}</p>`;
      return span;
    },

    cleanupAfterCopy(container) {
        const removeElements = () => {
            document.body.removeEventListener('transitionend', removeElements);
            if (document.body.contains(container)) {
                document.body.removeChild(container);
            }
        };

        document.body.addEventListener('transitionend', removeElements);
        container.style.transition = 'opacity 0.2s ease-out';
        container.style.opacity = 0;

        window.getSelection().removeAllRanges();
        this.showCopySuccessSnackbar();
    },

    showCopySuccessSnackbar() {
      this.snackbar = true;
      this.snackColor = "success";
      this.snackIcon = "check_circle";
      this.snackTitle = "Success";
      this.response = "Citation has been copied!";
    },

    noCopy() {
      this.snackbar = true;
      this.snackColor = "warning";
      this.snackIcon = "warning";
      this.snackTitle = "Warning";
      this.response = "These texts cannot be used for citation!";
    },
    calcControl(element) {
      if (element.status == "Controlling") {
        return true;
      }
      return false;
    },
    calcRein(element) {
      if (element.status == "Reinstated") {
        return true;
      }
      return false;
    },
    calcRepeal(element) {
      if (element.status == "Repealed") {
        return true;
      }
      return false;
    },

    handleSelectionChange(customToolbar) {
        const selection = window.getSelection();

        if (selection && !selection.isCollapsed) {
            const range = selection.getRangeAt(0);
            const rect = range.getBoundingClientRect();

            customToolbar.style.display = 'block';
            customToolbar.style.top = (rect.top + window.scrollY - customToolbar.offsetHeight) + 'px';
            customToolbar.style.left = (rect.left + window.scrollX) + 'px';

            document.addEventListener('keydown', (event) => {
                if ((event.metaKey || event.ctrlKey) && event.key === 'c') {
                    event.preventDefault();
                    this.copyTextWithCitation();
                }
            });
        } else {
            customToolbar.style.display = 'none';
        }
    },
  },
  
  mounted() {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    if (isSafari) {
        const customToolbar = document.createElement('div');
        customToolbar.id = 'customToolbar';
        customToolbar.classList.add('hidden-toolbar');
        customToolbar.style.position = 'absolute';
        customToolbar.style.zIndex = '9999';

        document.body.appendChild(customToolbar);

        document.addEventListener('selectionchange', () => {
            this.handleSelectionChange(customToolbar);
        });

        document.addEventListener('contextmenu', (event) => {
            event.preventDefault();
            this.handleSelectionChange(customToolbar);
            return false;
        });
    }
  },
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@300;400;700&display=swap');

.full-text-case {
  font-family: 'Merriweather', Georgia, serif !important;
}

.full-text-case >>> .text-overline,
.full-text-case >>> .text-h4,
.full-text-case >>> .text-h5,
.full-text-case >>> .text-h6,
.full-text-case >>> .text-body-1,
.full-text-case >>> .text-title,
.full-text-case >>> p,
.full-text-case >>> h3,
.full-text-case >>> h4,
.full-text-case >>> h5,
.full-text-case >>> h6,
.full-text-case >>> div,
.full-text-case >>> span {
  font-family: 'Merriweather', Georgia, serif !important;
}

.full-text-case >>> blockquote {
  font-family: 'Merriweather', Georgia, serif;
  font-style: italic;
  padding: 10px 20px;
  border-left: 5px solid #ccc;
  margin: 20px 0;
  line-height: 1.4;
}

.full-text-case >>> .blockquote-title {
  font-size: 1.1rem;
  font-style: italic;
}

.custom-hr {
  border-color: #31363F;
  border-width: 1px;
  margin: 20px 0;
}

.custom-h3 {
  letter-spacing: 0.5px;
  font-weight: bold;
  margin-bottom: 20px;
}

.scroll-to-top-btn {
  position: fixed;
  bottom: 16px;
  right: 16px;
  z-index: 1000;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

/* Paragraph styling */
.full-text-case >>> p {
  line-height: 1.6;
  margin-bottom: 1rem;
}

.action-btns {
  position: fixed;
  z-index: 4;
  left: 20px;  /* Adjust the position on the right side */
  top: 5rem; /* Adjust position at the bottom */
}

.nav-dark {
  background-color: #546E7A;
  border-radius: 10px;
}

.nav-light {
  background-color: #78909C;
  border-radius: 10px;
}

</style>